var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: false,
        "mask-closable": false,
        title: _vm.$t("New dictionary language"),
        destroyOnClose: ""
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("Language")))]),
      _c(
        "a-select",
        {
          style: { width: "100%" },
          attrs: { placeholder: _vm.$t("Select dictionary language") },
          model: {
            value: _vm.dictionaryLang,
            callback: function($$v) {
              _vm.dictionaryLang = $$v
            },
            expression: "dictionaryLang"
          }
        },
        _vm._l(_vm.langOptions, function(lang) {
          return _c(
            "a-select-option",
            { key: lang.value, attrs: { value: lang.value } },
            [_vm._v(" " + _vm._s(_vm.$t(lang.label)) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "12px" } },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("Dictionary name")))]),
          _c("a-input", {
            staticClass: "w-100",
            attrs: { placeholder: _vm.$t("Input dictionary name") },
            model: {
              value: _vm.dictionaryName,
              callback: function($$v) {
                _vm.dictionaryName = $$v
              },
              expression: "dictionaryName"
            }
          })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { staticClass: "mr-xs", on: { click: _vm.handleClose } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                disabled: !_vm.dictionaryLang || !_vm.dictionaryName,
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleAdd($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Create")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }