//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { LANGUAGES_POLLY, LANGUAGE_CODE_2_LABEL } from '@/enum'
export default {
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      dictionaryLang: '',
      dictionaryName: ''
    }
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('dictionary', ['dictionaryLangs']),
    langOptions() {
      return Object.entries(LANGUAGES_POLLY).map(([key, value]) => ({
        value: value,
        label: LANGUAGE_CODE_2_LABEL[key]
      })).filter(item => !this.dictionaryLangs.includes(item.value))
    }
  },
  methods: {
    ...mapActions('dictionary', ['addDictionary']),
    handleResetData() {
      this.dictionaryLang = ''
      this.dictionaryName = ''
    },
    handleClose() {
      this.handleResetData()
      this.$emit('close')
    },
    async handleAdd() {
      try {
        this.loading = true
        const payload = {
          name: this.dictionaryName,
          content: {},
          language: this.dictionaryLang,
          companyId: `${this.userInfo.company_id}`,
        }
        await this.$s.api.dictionary.createDictionary(payload);
        this.addDictionary(payload)
        this.$notification.success({
          message: this.$t("Create dictionary successfully")
        });
        this.handleClose()
      } catch (error) {
        this.$notification.error({ message: error.message });
      }
      this.loading = false
    }
  }
}
