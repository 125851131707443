//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      visible: false,
      dictionary: null,
      form: this.$form.createForm(this, { name: "dictionary" }),
    }
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    formItemOption() {
      return {
        name: {
          rules: [
            { required: true, message: this.$t('Please input dictionary name') },
            { max: 20, message: this.$t('Dictionary name must be less than 20 characters') },
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions('dictionary', ['updateDictionaryName']),
    handleOpen(dic) {
      this.dictionary = dic
      this.visible = true
      this.form?.setFieldsValue({ name: this.dictionary?.name || '' })
    },
    handleClose() {
      this.visible = false
    },
    handleSubmitForm() {
      if (!this.dictionary?.sk) return;

      this.form.validateFields(async (err, values) => {
        if (err) return;
        this.handleUpdateDictionaryName(values.name)
      })
    },
    async handleUpdateDictionaryName(dicName) {
      try {
        this.loading = true
        await this.$s.api.dictionary.updateDictionaryName(this.dictionary.sk, dicName);
        this.updateDictionaryName({ oldName: this.dictionary.name, newName: dicName })
        this.$notification.success({
          message: this.$t("Update the dictionary name successfully")
        });
        this.handleClose()
      } catch (error) {
        this.$notification.error({ message: error.message });
      }
      this.loading = false
    }
  }
}
