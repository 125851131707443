var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: false,
        "mask-closable": false,
        title: _vm.$t("Are you sure to delete this dictionary language?"),
        destroyOnClose: ""
      }
    },
    [
      _c("div", [
        _vm._v(
          _vm._s(
            _vm.$t("This action will delete all the dictionarys inside!")
          ) + " "
        )
      ]),
      _c("div", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "To confirm deletion, under input field please enter: delete"
            )
          )
        }
      }),
      _c("a-input", {
        attrs: { placeholder: "delete" },
        model: {
          value: _vm.deleteConfirmInput,
          callback: function($$v) {
            _vm.deleteConfirmInput = $$v
          },
          expression: "deleteConfirmInput"
        }
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { staticClass: "mr-xs", on: { click: _vm.handleClose } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.loading,
                disabled: _vm.deleteConfirmInput !== "delete"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("OK")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }