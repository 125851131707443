var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-table",
    {
      attrs: {
        columns: _vm.columns,
        "data-source": _vm.dataSource,
        bordered: "",
        size: "small",
        pagination: false
      },
      scopedSlots: _vm._u([
        {
          key: "index",
          fn: function(text, record, index) {
            return [_vm._v(_vm._s(index + 1))]
          }
        },
        {
          key: "word",
          fn: function(text, record, index) {
            return [
              _c("a-input", {
                staticStyle: { margin: "-5px 0" },
                attrs: { value: text },
                on: {
                  change: function(e) {
                    return _vm.handleChangeWord(e.target.value, record.key)
                  }
                }
              })
            ]
          }
        },
        {
          key: "reading",
          fn: function(text, record, index) {
            return [
              _c("a-input", {
                staticStyle: { margin: "-5px 0" },
                attrs: { value: text },
                on: {
                  change: function(e) {
                    return _vm.handleChangeReading(e.target.value, record.key)
                  }
                }
              })
            ]
          }
        },
        {
          key: "operation",
          fn: function(text, record, index) {
            return [
              _vm.dataSource.length
                ? _c(
                    "a-popconfirm",
                    {
                      attrs: { title: "Are you sure to delete?" },
                      on: {
                        confirm: function() {
                          return _vm.handleDelete(record.key)
                        }
                      }
                    },
                    [
                      _c("a-button", {
                        attrs: {
                          icon: "delete",
                          type: "danger",
                          ghost: "",
                          size: "small"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("a-button", {
                staticStyle: { "margin-left": "4px" },
                attrs: {
                  icon: record.isPlaying ? "pause-circle" : "play-circle",
                  type: "primary",
                  ghost: "",
                  size: "small",
                  disabled: !record.reading
                },
                on: {
                  click: function() {
                    return record.isPlaying
                      ? _vm.handlePause(record)
                      : _vm.handlePlay(record)
                  }
                }
              })
            ]
          }
        },
        {
          key: "footer",
          fn: function(currentPageData) {
            return _c(
              "div",
              { staticStyle: { "text-align": "end" } },
              [
                _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      "ok-text": _vm.$t("Yes"),
                      "cancel-text": _vm.$t("No"),
                      title: _vm.$t("Are you sure to update this dictionary?")
                    },
                    on: { confirm: _vm.handleUpdateDictionary }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function(e) {
                            return e.stopPropagation()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Save")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }
        }
      ])
    },
    [
      _c(
        "template",
        { slot: "customOperation" },
        [
          _c(
            "a-tooltip",
            { attrs: { placement: "top", title: _vm.$t("Add") } },
            [
              _c("a-button", {
                attrs: {
                  shape: "circle",
                  type: "primary",
                  icon: "plus-circle"
                },
                on: { click: _vm.handleAdd }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }