//
//
//
//
//

import DictionarySetting from '@/components/SpeechSetting/DictionarySetting.vue';

export default {
  components: { DictionarySetting }
}
