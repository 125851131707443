//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    dictionary: { type: Object, default: () => ({}) }
  },
  data: () => ({
    loading: false,
    dataSource: [],
    count: 0,
    audio: null
  }),
  computed: {
    columns() {
      return [
        {
          title: 'No',
          dataIndex: 'index',
          width: '50px',
          scopedSlots: { customRender: 'index' },
        },
        {
          title: 'Word',
          dataIndex: 'word',
          width: '40%',
          scopedSlots: { customRender: 'word' },
        },
        {
          title: 'Reading',
          dataIndex: 'reading',
          width: '40%',
          scopedSlots: { customRender: 'reading' },
        },
        {
          dataIndex: 'operation',
          align: 'center',
          slots: { title: 'customOperation' },
          scopedSlots: { customRender: 'operation' },
        },
      ];
    },
    content() {
      return this.dictionary?.content || {}
    },
    lang() {
      return this.dictionary?.language || 'en-US'
    }
  },
  methods: {
    ...mapActions('dictionary', ['updateDictionary']),
    handleAdd() {
      const newData = {
        key: this.count,
        word: '',
        reading: '',
        isPlaying: false
      };
      this.dataSource = [...this.dataSource, newData];
      this.count = this.count + 1;
    },
    handleDelete(key) {
      const newDataSource = [...this.dataSource];
      this.dataSource = newDataSource.filter(item => item.key !== key);
    },
    handleChangeWord(value, key) {
      const newDataSource = [...this.dataSource];
      const target = newDataSource.find(item => key === item.key);
      if (target) {
        target.word = value;
        this.dataSource = newDataSource;
      }
    },
    handleChangeReading(value, key) {
      const newDataSource = [...this.dataSource];
      const target = newDataSource.find(item => key === item.key);
      if (target) {
        target.reading = value;
        this.dataSource = newDataSource;
      }
    },
    handleSubmit() {
      return this.dataSource.reduce((acc, item) => {
        if (!item.word || !item.reading) return acc;

        return { ...acc, [item.word]: item.reading }
      }, {})
    },
    async getSynthesizeSpeech(text, lang) {
      const payload = {
        sentence: text,
        gender: 'male',
        language: lang,
        textType: 'text',
        engine: 'standard',
      };
      const res = await this.$s.api.sound.getSynthesizeSpeechStream(payload);
      if (!res?.mp3Stream?.data) return '';

      const bufferArray = res?.mp3Stream?.data;
      const audioBlob = new Blob([new Uint8Array(bufferArray)], {
        type: 'audio/mp3'
      });
      const url = URL.createObjectURL(audioBlob);
      return url;
    },
    async handlePlay(record) {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      }
      try {
        record.isPlaying = true
        const { word, reading } = record
        const url = await this.getSynthesizeSpeech(reading, this.lang);
        this.audio = new Audio(url);
        this.audio.play();
        this.audio.onended = () => this.handleEnd(record);
      } catch (error) {
        this.$notification.error({
          message: this.$t(error.TypeError) || this.$t('Network error')
        });
      }
    },
    handleEnd(record) {
      record.isPlaying = false
    },
    handlePause(record) {
      record.isPlaying = false
      this.audio.pause();
      this.audio = null;
    },
    async handleUpdateDictionary() {
      try {
        this.loading = true;
        const payload = {
          ...this.dictionary,
          content: this.handleSubmit()
        }
        await this.$s.api.dictionary.updateDictionary(payload);
        this.updateDictionary(payload)
        this.$notification.success({
          message: this.$t("Update dictionary successfully")
        });
      } catch (error) {
        this.$notification.error({ message: error.message });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    'content': {
      handler() {
        this.dataSource = []
        if (!this.content) return;
        for (const [key, value] of Object.entries(this.content)) {
          this.dataSource.push({
            key: this.count,
            word: key,
            reading: value,
            isPlaying: false
          })
          this.count = this.count + 1;
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
  },
}
