//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import DictionaryTable from './DictionaryTable.vue';
import AddDictionaryDialog from './AddDictionaryDialog.vue'
import AddDictionaryByLangDialog from './AddDictionaryByLangDialog.vue';
import DeleteDictionaryByLangDialog from './DeleteDictionaryByLangDialog.vue';

export default {
  components: {
    DictionaryTable,
    AddDictionaryDialog,
    AddDictionaryByLangDialog,
    DeleteDictionaryByLangDialog
  },
  data() {
    return {
      loading: false,
      currentTab: null,
      addDictionaryDialogVisible: false,
      addDictionaryByLangDialogVisible: false,
      deleteDictionaryByLangDialogVisible: false,
      removedLangTarget: ''
    }
  },
  computed: {
    ...mapGetters('dictionary', ['dictionaryLangs', 'dictionariesGroupByLang']),
  },
  methods: {
    ...mapActions('dictionary', ['updateDictionary', 'deleteDictionaryByNames']),
    async handleDeleteDictionary(name) {
      try {
        this.loading = true;
        await this.$s.api.dictionary.deleteDictionaries([name]);
        this.deleteDictionaryByNames([name])
        this.$notification.success({
          message: this.$t("Delete dictionary successfully")
        });
      } catch (error) {
        this.$notification.error({ message: error.message });
      } finally {
        this.loading = false;
      }
    },
    handleEditTabs(targetKey, action) {
      if (action === "add") {
        this.addDictionaryByLangDialogVisible = true;
      }
      if (action === "remove") {
        this.deleteDictionaryByLangDialogVisible = true;
        this.removedLangTarget = targetKey
      }
    },
  },
  watch: {
    dictionaryLangs: {
      handler() {
        if (this.dictionaryLangs.length > 0 && !this.currentTab) {
          this.currentTab = this.dictionaryLangs[0]
        }
      },
      immediate: true
    }
  }
}
